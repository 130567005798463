<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">园区管理</div>
      <div class="h2">科技铸就辉煌品质    循环打造生态园区</div>
    </div>
    <div class="yuan">
      <div class="title1">
        <div>园区管理</div>
        <div class="title2">企业入驻园区 享地方扶持奖励 专项产业发展扶持政策</div>
      </div>
      <div class="zhuti">
        <div class="flex"  style="margin: 60px 0">
          <div class="glleft">
            <div class="mgr31">
              <div class="glh1">园区服务优势</div>
              <div>企业入驻园区 享地方扶持奖励 专项产业发展扶持政策</div>
              <div class="glh2">
                <div><i class="glbg1"></i><span>政策稳</span></div>
                <div><i class="glbg2"></i><span>扶持高</span></div>
                <div ><i class="glbg3"></i><span>服务好</span></div>
              </div>
            </div>
          </div>
          <div class="glright flex-1">
            <div style="overflow: hidden">
              <el-row >
                <el-col style="height: 172px" span="6" v-for="(li,index) in arr" :key="index">
                  <div class="imglist">
                    <div class="img"><el-image :src="li.src"></el-image></div>
                    <div>{{li.name}}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="glbottom">企业入驻园区 享地方扶持奖励 专项产业发展扶持政策<el-button  class="btn" type="primary" @click="go(3)">进入园区管理</el-button></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg1">
      <div class="zhuti " >
        <div class="buton flex">
          <div class="tab" @click="go(1)">
            <div class="tabh1">园区展示</div>
            <div>专业的园区管理服务，提供全方位的支持</div>
          </div>
          <div class="tab mgr135" @click="go(2)">
            <div class="tabh1 ">园区政策</div>
            <div>专业的园区管理服务，提供全方位的支持</div>
          </div>
        </div>
        <!--        <div class="tab" @click="go(3)">-->
        <!--          <div class="tabh1">申请入驻</div>-->
        <!--          <div>专业的园区管理服务，提供全方位的支持</div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="bg">
      <div class="zhuti">
        <div class="title textalgin">企业入园流程</div>
        <div class="bg4"></div>
      </div>

    </div>
    <div class="bg2">
      <div class="zhuti">
        <div class="title">园区展示 <span class="more" @click="gomore">查看更多<i class="el-icon-arrow-right"></i></span></div>
        <div class="zhanshi">
          <div class="list" v-for="(item,index) in parkList" @click="goxq(item)" :key="index">
            <div class="images"><el-image :src="item.image"></el-image></div>
            <div class="listtitle text-overflow">{{item.name}}</div>
            <div  class="text-overflow"><el-tag type="info" size="small" style="margin-right: 5px" v-for="(li,i) in item.industry?item.industry.split(','):''" :key="i">{{li}}</el-tag></div>
            <el-divider></el-divider>
            <div class="cor" style="margin: 8px 0"><i class="el-icon-phone-outline"></i><span>{{item.headPhone}}</span></div>
            <div  class="cor text-overflow"><i class="el-icon-location-information"></i><span>{{item.provinceName}}{{item.cityName}}{{item.countyName}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg">
      <div class="zhuti">
        <div class="title textalgin">重点扶持行业</div>
        <div class="zhongdian">
          <div class="bg3" v-for="(item,index) in industryList" :key="index">
            {{item.title}}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="zhuti wenda">
        <div class="title textalgin">热门问答</div>
        <div class="shichang flex">
          <div class="scleft">
            <div class="ul" :class="{'checked':number==index}" @click="choosecheck(index)" v-for="(li,index) in hotQA" :key="index">
              <span class="xuhao">{{index+1}}</span>{{li.title}}
            </div>
          </div>
          <div class="flex-1 scright" >
            <div class="deatal">
              <div  class="flex sctitle"><div class="wen">问</div><div class="flex-1">{{hotQA[number].title}}</div></div>
              <div class="flex scda"><div class="da">答</div><div class="flex-1 scdas" v-html="hotQA[number].text"></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {policyindex, policypage} from "../../request/moudle/zcfw";
import {parkindex, parklist} from "../../request/moudle/park";
import config from "../../utils/config";
export default {
  name: "zchj",
  data(){
    return {
      number:0,
      page:{
        current:1,
        size:10,
        total:0
      },
      parkList:[],
      industryList:[],
      hotQA:[],
      datas:'',
      arr:[{name:'政策长期稳定',src:require('../../assets/park/img.png')},
        {name:'营商环境优质',src:require('../../assets/park/img_1.png')},
        {name:'专享行业扶持',src:require('../../assets/park/img_2.png')},
        {name:'区域优势明显',src:require('../../assets/park/img_3.png')},
        {name:'高新/上市补贴',src:require('../../assets/park/img_4.png')},
        {name:'产业集聚优势',src:require('../../assets/park/img_5.png')},
        {name:'联动发展优势',src:require('../../assets/park/img_6.png')},
        {name:'专业化服务优势',src:require('../../assets/park/img_7.png')}
      ]
    }
  },
  created() {
    this.loadconfig()
  },
  methods:{
    loadconfig(){
      this.$api.park.parkindex().then((res)=>{
        this.datas=res.data
        this.parkList=res.data.parkList
        this.industryList=res.data.industryList
        this.hotQA=res.data.hotQA
      })
    },
    choosecheck(index){
      this.number=index
    },
    go(num){
      if(num==1){
        this.$router.push({
          path:'/yqgl',
        })
      }else if(num==2){
        this.$router.push({
          path:'/parkzchj',
        })
      }else{
        window.open(config.adminhref+'#/park/index','_blank')
      }
    },
    gomore(){
      this.$router.push({
        path:'/yqgl',
      })
    },
    goxq(item){
      this.$router.push({
        path:'/yqglxq',
        query:{
          id:item.id,
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.yuan{
  width: 1200px;
  margin: 0 auto;
  background: url("../../assets/park/yuan.png") no-repeat;
  background-size: 1200px 593px;
}
.header{
  height: 220px;
  background: url("../../assets/banner/yqgl.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.glleft{
  overflow: hidden;
  width: 280px;
  height: 448px;
  background: url("../../assets/park/bg5.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
  .mgr31{
    margin: 41px 38px;
  }
  .glh1{
    font-size: 24px;
    margin-bottom: 10px;
  }
  .glh2{
    font-size: 24px;
    margin-top: 115px;
    div{
      margin-top: 35px;
    }
  }
  .glbg1{
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url("../../assets/park/glbg1.png");
    background-size: 100% 100%;
    vertical-align: middle;
    margin-top: -5px;
    margin-right: 10px;
  }
  .glbg2{
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url("../../assets/park/glbg2.png");
    background-size: 100% 100%;
    vertical-align: middle;
    margin-top: -5px;
    margin-right: 10px;
  }
  .glbg3{
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url("../../assets/park/glbg3.png");
    background-size: 100% 100%;
    vertical-align: middle;
    margin-top: -5px;
    margin-right: 10px;
  }
}
.glright{
  color: #fff;
  .glbottom{
    background: linear-gradient(119deg, #313F6D 0%, #23315F 100%);
    line-height: 102px;
    padding: 0 38px;
    .btn{
      float: right;
      display: inline-block;
      margin-top: 35px;
    }
  }
  .imglist{
    height: 172px;
    border-right:1px solid rgba(255,255,255,0.2);
    border-bottom:1px solid rgba(255,255,255,0.2);
    background: linear-gradient(119deg, #313F6D 0%, #23315F 100%);
    text-align: center;
  }
  .img{
    width: 64px;
    height: 64px;
    margin: 0px auto;
    padding-top: 38px;
    margin-bottom: 15px;
  }
}
.bg4{
  height: 86px;
  background: url("../../assets/park/bg4.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 40px;
}
.wenda{
  padding: 56px 0;
}
.shichang{
  border-radius: 2px;
  border: 1px solid #ddd;
  margin-top: 35px;
  .scleft{
    width: 420px;
    .xuhao{
      margin-right: 10px;
    }
    .ul{
      padding: 26px 32px;
      cursor: pointer;
    }
    .checked{
      background: #fff;
    }
  }
  .scright{
    background: #fff;
    line-height: 32px;
    .deatal{
      margin: 38px 48px;
    }
    .sctitle{
      margin-bottom: 32px;
      font-size: 18px;
      font-weight: bolder;
    }
    .scda{
      margin-bottom: 32px;
      color: #808080;
    }
    .scdas{
      height: 400px;
      overflow: auto;
    }
    .wen{
      width: 32px;
      height: 32px;
      border-radius: 6px;
      background: rgba(0, 121, 233, 0.10);
      color: #0079E9;
      font-size: 16px;
      vertical-align: middle;
      text-align: center;
      line-height: 32px;
      margin-right: 20px;
    }
    .da{
      width: 32px;
      height: 32px;
      border-radius: 6px;
      background: #0079E9;
      color: #fff;
      font-size: 16px;
      vertical-align: middle;
      text-align: center;
      line-height: 32px;
      margin-right: 20px;
    }
  }
}
.bg{
  background: #fff;
  padding:56px 0 ;
}
.bg1{
  height: 200px;
  background: url("../../assets/park/bg1.png") no-repeat;
  background-size: 100% 100%;
}
.bg2{
  background: url("../../assets/park/bg2.png") no-repeat;
  background-size: 100% 100%;
  padding: 56px 0;
}
.zhongdian{
  overflow: hidden;
  margin-top: 40px;
  .bg3{
    background: url("../../assets/park/bg3.png") no-repeat;
    background-size: 100% 100%;
    width: 167px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    border-radius: 4px;
    margin: 2px;
    color: #fff;
    float: left;
  }
}
.zhanshi{
  overflow: hidden;
  background: #fff;
  margin-top: 24px;
  border-radius: 6px;
  .list{
    width: 264px;
    margin: 16px 18px;
    float: left;
    cursor: pointer;
    .listtitle{
      font-weight: bolder;
      font-size: 16px;
      line-height: 50px;
    }
    .cor{
      color: #808080;
    }
    .el-icon-phone-outline,.el-icon-location-information{
      margin-right: 8px;
    }
    .images{
      width:264px;
      height: 177px;
    }
  }
  .list:nth-child(1){
    margin: 16px 18px 16px 12px;
  }
  .list:nth-last-child(1){
    margin: 16px 12px 16px 18px;
  }
}
.buton{
  width: 800px;
  margin: 0 auto;
}
.tab{
  width: 310px;
  height: 85px;
  background: #FFFFFF;
  color: #1D1D1D;
  border-radius: 6px;
  margin-top: 57px;
  text-align: center;
  cursor: pointer;
  .tabh1{
    font-size: 20px;
    font-weight: bolder;
    padding: 10px 0;
  }
}
.mgr135{
  margin: 57px 135px;
  margin-right: 0;
}
.title{
  font-size: 20px;
  font-weight: bolder;
  .more{
    color: #1D1D1D;
    font-size: 14px;
    font-weight: normal;
    float: right;
    cursor: pointer;
  }
}
.textalgin{
  text-align: center;
}
.title1{
  font-size: 28px;
  font-weight: bolder;
  padding: 32px 0;
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  .title2{
    color: #999;
    font-size: 16px;
    line-height: 45px;
  }
}
.zhuti{
  width: 1200px;
  margin:0px auto;
  .sxx{
    background: #fff;
    padding: 20px;
  }
}
</style>